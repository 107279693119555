import { styled } from './../../styles/theme.config.js'

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flexDirection  : 'column',
    height         : '100vh',
    width          : '100vw',
    zIndex         : -1,
    marginTop : '1em',
    '@media only screen and (max-width : 1000px)' :{
        display : 'none',
      },
  })
  
  export const Content = styled('div', {
      position : 'relative',
      width : "85%",
      background: '#F6F6F6',
      backgroundRepeat : 'no-repeat',
      backgroundSize : 'contain',
      height : '80vh',
      backgroundPosition : 'right',
      color : '$black',
      '@media only screen and (max-width : 600px)' :{
          backgroundPosition : 'bottom',
      },
  })
  
  export const Para = styled('div', {
    //   position : 'absolute',
      width : '80%',
      fontSize : '$3',
      fontWeight : 'bold',
      lineHeight : '2em',
      color : '$white',
      zIndex : 99,
      userSelect : 'none',
      '@media only screen and (max-width : 1000px)' :{
          textAlign: 'center',
          width : '100%',
          fontSize : '$2'
      },
  
  })
    
  export const Image = styled('img',{
      position : 'absolute',
      right : 0,
      width: '100%',
      height : '100%',
      display: 'block',
      "@media only screen and (max-width : 600px)": {
          width : '100%',
          display:'inline-block !impotant'
      },
  })
  
  export const Box = styled('div', {
      position : 'relative',
      width : "90%",
      background: '#f6f6f6',
      backgroundRepeat : 'no-repeat',
      backgroundSize : 'contain',
      height : '85vh',
      backgroundPosition : 'right',
      '@media only screen and (max-width : 600px)' :{
          transform : 'translateY(3em)'
      },
  })

  export const Header = styled('h1', {
    fontSize     : '42px',
    lineHeight   : '60px',
    marginBottom : '0.5em',
    color : '$white',

})

export const Button = styled('a', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    background     : '$button',
    fontSize       : '$2',
    fontWeight     : '600',
    height         : '3em',
    width          : '15vw',
    padding        : '0.5em 1.5em',
    margin         : '3em 0',
    borderRadius   : '4px',
    border         : 'none',
    color          : '$white',
    textDecoration : 'none',
  })

  export const Data = styled('div' , {
    position : 'absolute',
    zIndex : '9999',
    width : '75%',
    transform : 'translate(4em, 4em)'
  })