import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flexDirection  : 'column',
    width          : '100vw',
    zIndex         : -1,
    backgroundColor: '$grey',
    color          : '$white',
    padding : '1.5em 0 0 1em',
    '@media only screen and (max-width : 600px)' :{
        alignItems     : 'flex-start',
    }
})

export const Content = styled('div', {
    display : 'flex',
    justifyContent : 'space-between',
    lineHeight : '2em',
    '@media only screen and (max-width : 600px)' :{
        flexDirection : 'column',
        alignItems : 'flex-start',
        justifyContent : 'left',
    },
})

export const Box = styled('div', {
    margin : '3em 4em',
    '@media only screen and (max-width : 600px)' :{
        margin : '1em 1em',
    },
})

export const CompanyCred = styled('div', {
    display : 'flex',
    height : '100%',
    flexDirection : 'column',
    justifyContent : 'space-between',
})

export const Header = styled('h1', { 
    margin : '1em 1em',
    '@media only screen and (max-width : 600px)' :{
        fontSize : '$4',
        margin : '1em 0em',
    }
})

export const Text = styled('p', {
    margin : '1em 0',
    color : '#cccccc',
})

export const BoldText = styled('p', {
    margin : '1em 0',
    fontWeight : 'bold',
})

export const ListText = styled('a', {
    margin : '1em 3em 1em 0',
    display : 'block',
    textDecoration : 'none',
    color : '$white',
    '&:hover' : {
        color : '#cccccc',
    }
})

export const BrandBox = styled('div', {
    display : 'flex',
    maxWidth : '20em',
    flexWrap : 'wrap',

    '@media only screen and (max-width : 600px)' :{
        padding : 0,
        display : 'block'
    },
})

export const ProdBox = styled('div', {
    '@media only screen and (max-width : 600px)' :{
        padding : 0
    },
})

export const Image = styled('img', {
    width : '80px',
    margin : 'auto 0em'
})