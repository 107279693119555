import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flexDirection  : 'column',
    width          : '100vw',
    zIndex         :  -1,
    margin         : '2em 0',
})

export const Box = styled('div', {
    display : 'flex',
    width   : '80vw',
    margin  : '4em 0',
    flexDirection : 'column',
    '@media only screen and (max-width : 750px)' :{
        width         : '80vw',
        alignItems    : 'flex-start',
        margin        : '2em 0',
    },
})

export const Header = styled('h1', {
    paddingTop : '1em',
})

export const Text = styled('p', {
    padding     : '2em 0 0 0',
    color       : '$black',
    lineHeight  : '32px',
    '@media only screen and (max-width : 750px)' :{
      textAlign : 'justify',
      padding   : '1em 0em',
    },
})

export const Image = styled('img', {
    width : '80vw',
    '@media only screen and (max-width : 750px)' :{
        width : '100%',
    },
})

export const Content = styled('div', {
    display        : 'flex',
    flexDirection  : 'column',
    justifyContent : 'center',
    padding        : '5em 0 0 0',
    width          : '80vw',
    '@media only screen and (max-width : 750px)' :{
        width      : '100%',
        padding    : '2em 0em',
    },
})

export const Video = styled('video', {
    
})