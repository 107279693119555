import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import video from './../../assets/rajat_video.mp4';

import * as S from './About.style.js';
import globalStyle from "../../styles/global.style";

const About = ({data}) => {
    globalStyle();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        adaptiveHeight: true,
      };

    return (
        <S.Container id="about">
            <S.Box>
                <S.Header>Our journey</S.Header>
                <S.Text>
                Since our establishment in 1989, Rajat International aspires to be a model of excellence by employing India's traditional handwork in the fields of printing and embroidery/needlework, with the goal of preserving India's ancient skill and art.  <br /><br />

                Our highly skilled artisans use the latest technologies to create fashion articles that are setting new benchmarks in the apparel industry. Our artisans create, develop, manufacture readymade ladies’ and men’s garments with hand block print/ hand screen prints and hand embroidery techniques. <br /><br />

                Our products are offered in a variety of styles, and we keep an active eye on the latest fashion trends for womenswear and menswear, we specialize in small orders to cater to select shop buyers.  <br /><br />
                {/* <ul style={{listStyleType : "none"}}>
                <li>Hand Tufted: 30,000 sq meter</li>
                <li>Hand Knotted: 5,000 sq meter</li>
                <li>Hand Woven: 20,000 sq meter</li>
                <li>Handloom: 10,000 sq meter</li>
                <li>Pillows: 60,000 pcs.</li>
                <li>Poufs: 10,000 pcs.</li>
                </ul> */}
                </S.Text>
                <S.Content>
                    <S.Video src={video} controls="controls" autoplay="false" />
                </S.Content>
            </S.Box>
        </S.Container>

        // <S.Container id="about">
        //     {data.map((value, key) => (
        //         key % 2 == 0 
        //         ? 
        //         <S.Box>
        //             <S.Content>
        //                 <S.Header>
        //                     {value.title}
        //                 </S.Header>
        //                 <S.Text>
        //                     {value.text}
        //                 </S.Text>
        //             </S.Content>
        //             <S.Content>
        //                 <S.Image src={value.image}/>
        //             </S.Content>
        //         </S.Box>
        //         :
        //         <S.Box>
        //             <S.Content>
        //                 <S.Image src={value.image}/>
        //             </S.Content>
        //             <S.Content>
        //                 <S.Header>
        //                     {value.title}
        //                 </S.Header>
        //                 <S.Text>
        //                     {value.text}
        //                 </S.Text>
        //             </S.Content>
        //         </S.Box>
        //     ))}
        // </S.Container>

    )
}

export default About;