import * as S from './Hero.style.js';
import './index.css';

const Hero = ({background, mainText, subText}) => {
    return (
        <S.Container id="home">
            {/* <S.Content style={{ backgroundImage:`url(${background})`}}>
                <S.Para>{text}</S.Para>
            </S.Content> */}
            <S.Box>
                <S.Content>
                    <S.Header className='heading'>{mainText}</S.Header>
                    <S.Para>{subText}</S.Para>
                    <S.Button href='/#products'>View products</S.Button>
                </S.Content>
            </S.Box>
        </S.Container>
    )
}

export default Hero;