
import * as S from './HeroBackground.style.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import background1 from '../../assets/Background_1.png';
import background2 from '../../assets/Background_2.png';
import background3 from '../../assets/Background_3.png';

import Slider from "react-slick";
import './style.css';

const backgroundImgArray = [background1, background2, background3]

function EmptyButton(props) {
    const { className, style, onClick } = props;
    return (
        <div/>
    );
}

const HeroBackground = ({background, mainText, subText}) => {

    const settings = {
        dots: false,
        className: "center",
        autoplay: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 3000,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow : <EmptyButton />, 
        prevArrow : <EmptyButton />, 
      };

    return (
        <S.Container id="home">
            <S.Content>
                <S.Data>
                    <S.Header className='heading'>{mainText}</S.Header>
                    <S.Para>{subText}</S.Para>
                    <S.Button href='/#products'>View products</S.Button>
                </S.Data>
                <Slider {...settings}>
                    {backgroundImgArray.map(image => (
                    <S.Box>
                        <S.Image src={image}/>
                    </S.Box>
                    ))}
                </Slider>
            </S.Content>
        </S.Container>
    )
}

export default HeroBackground;