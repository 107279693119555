import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


import * as S from './ImageGallery.style.js';
import './index.css';

function EmptyButton(props) {
    const { className, style, onClick } = props;
    return (
        <div/>
    );
}

const ImageGallery = ({id, title, imgArray, isCarousel}) => {

    const settings = {
        dots: false,
        className: "center",
        autoplay: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 3000,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow : <EmptyButton />, 
        prevArrow : <EmptyButton />, 
      };


    return (
        <S.Container id={id}>
            <S.Header>{title}</S.Header>
              <Slider {...settings}>
                  {imgArray.map((value, key) => (
                  <S.Content>
                      <S.Link href={"/"} target='_blank'>
                          <S.Image src={value.image}/>
                      </S.Link>
                  </S.Content>
                  ))}
              </Slider>
          
        </S.Container>
    )
}

export default ImageGallery;