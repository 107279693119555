import * as S from './Sustainability.style.js';

const Sustainability = ({data}) => {
    return (
        <S.Container>
            <S.Header>{data.title}</S.Header>
            <S.Image src={data.image}/>
            <S.Text>
            We are acutely aware of the environmental and human rights issues that global sourcing poses, and we have taken a number of key actions to guarantee that our values are respected throughout the network that supports our company.  <br />
            At Rajat International, our purpose is to build a better world for all hardworking people. This includes ensuring a safe working space for every person working to stitch, sew and put the finishing touches on our products.  <br />
            Our facility is frequently audited by an accredited third-party social compliance certification programme to guarantee that the standards for social compliance are satisfied.<br />
            </S.Text>
        </S.Container>
    )
}

export default Sustainability;