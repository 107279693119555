import "./App.css";
import globalStyle from "./styles/global.style.js";

import { Layout } from "./styles/theme.config.js";
import Navigation from "./components/Navigation/Navigation";
import Hero from "./components/Hero/Hero";
import HeroBackground from "./components/HeroBackground/HeroBackground";
import Products from "./components/Products/Product";
import About from "./components/About/About";
import Description from "./components/Description/Description";
import Artisans from "./components/Artisans/Artisans";
import ImageGallery from "./components/ImageGallery/ImageGallery";
import Sustainability from "./components/Sustainability/Sustainability";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

import data from "./data.js";
import { Descriptions } from "antd";

const App = () => {
  globalStyle();

  return (
    <Layout>
      <Navigation logo={data.logo} companyName={data.companyName} />

      <HeroBackground
        background={data.background}
        mainText={data.mainText}
        subText={data.subText}
      />

      <Hero
        background={data.background}
        mainText={data.mainText}
        subText={data.subText}
      />

      <ImageGallery
        id=""
        title=""
        imgArray={data.backgroundImgArray}
        isCarousal={false}
      />

      <Products
        productSectionTitle={data.productSectionTitle}
        prodArray={data.prodArray}
        buttonContent={data.buttonContent}
      />

      <About data={data.aboutData} />

      <Description />

      <Artisans data={data.artisansData} />

      {/* <ImageGallery
        id="brands" 
        title={data.brandsTitle} 
        imgArray={data.brandArray} 
        isCarousel={true} 
      /> */}

      <Sustainability data={data.sustainabilityData} />

      <Contact {...data}/>

      <Footer
        logo={data.logo}
        companyName={data.companyName}
        prodArray={data.prodArray}
        brandArray={data.brandArray}
      />
    </Layout>
  );
};

export default App;
