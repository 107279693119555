import { styled } from '../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flexDirection  : 'column',
    width          : '90vw',
    zIndex         : -1,
    padding : '3em 0',
    background : '#F5F5F5',
    margin : '4em auto',
})

export const Header = styled('h2', {
    textAlign : 'center',
    '@media only screen and (max-width : 750px)' :{
        padding   : '1em 1em',
    },
})

export const Image = styled('img', {
    width : '87%',
    margin : '2em',
})

export const Text = styled('p', {
    width  : '80vw',
    padding : '0 1em',
    textAlign : 'left',
    color       : 'rgba(0, 0, 0, 0.7)',
    lineHeight  : '32px',
    '@media only screen and (max-width : 750px)' :{
        padding   : '0',
    },
})