import * as S from './Products.style.js';

const Products = ({productSectionTitle, prodArray, buttonContent}) => {
    return (
        <S.Container id="products">
            <S.Header>{productSectionTitle}</S.Header>
            <S.Content>
                {prodArray.map((product, key) => (
                    <S.Product href={product.link} target="_blank">
                        <S.Image src={product.image}/>
                        <S.Title>{product.title}</S.Title>
                        <S.Link href={product.link} target="_blank">View products</S.Link>
                    </S.Product>
                ))}
            </S.Content>
            <S.Button href="/#contact">
                {buttonContent}
            </S.Button>
        </S.Container>
    )
}

export default Products;