import * as S from './Description.style.js';
import Desc_1 from './../../assets/Desc_1.png';
import Desc_2 from './../../assets/Desc_2.png';

const Description = () => {
    return (
        <S.Container>
            <S.Box>
                <S.Content>
                    <S.Heading> Mission </S.Heading>
                    <S.Para> 
                        At Rajat Internationals, with our professionalism and overall determination, we aim to be a mirror of perfection by using India's traditional methods of hand printing, embroidery/needle, and meeting the customer’s request for readymade garments. We work with the motto of keeping the traditionality of India alive. <br /> <br />  
                        Our team strives to create an amazing experience for our customers from beginning to end. We take great pride in getting to know our clients' lifestyles, aspirations, and budgets, and then collaborating with them to meet their needs. <br />
                    </S.Para>
                </S.Content>
                <S.Content>
                    <S.Image src={Desc_1}/>
                </S.Content>
            </S.Box>

            <S.Box>
                <S.Content className="hideMedium">
                    <S.Image src={Desc_2}/>
                </S.Content>
                <S.Content>
                    <S.Heading> Why Rajat Internationals? </S.Heading>
                    <S.Para>  

                        <b>Affordable Prices </b> <br />
                        All production steps from cutting to sewing, packaging, and controlling are handled by our well-qualified employees, which keeps our costs low.  <br /> <br />

                        <b> Years of Expertise  </b> <br />
                        We have highly skilled & experienced embroiders working with us, providing you with custom-made embroidered garments. <br /> <br />

                        <b> Fast & Secure Shipping </b> <br />
                        At Rajat Internationals, we respect our client’s time so we ensure that the shipping should be on time with all the safety measures. <br /> <br />

                    </S.Para>
                </S.Content>
                <S.Content className="showMedium">
                    <S.Image src={Desc_2}/>
                </S.Content>
            </S.Box>

        </S.Container>
    )
}

export default Description;
