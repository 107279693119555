import Logo from './assets/logo.png';
import Background from './assets/Hero_image.png';
import Product_1 from './assets/Product_1.png';
import Product_2 from './assets/Product_2.png';
import Product_3 from './assets/Product_3.png';
import Product_4 from './assets/Product_4.png';
import Product_5 from './assets/Product_5.png';
import Product_6 from './assets/Product_6.png';

import background_1 from './assets/Background_1m.png';
import background_2 from './assets/Background_2m.png';
import background_3 from './assets/Background_3m.png';


import artisans from './assets/artisans.png'
import sustainability from './assets/sustainability.png';

const data = {
    'companyName': "Rajat International",
    "mainText": "Readymade garment exports",
    "subText": "Rajat Internationals is the leading custom clothing manufacturer located in Haryana– India and manufactures a wide range of high-quality readymade womenswear and menswear.",
    'productSectionTitle': "Our Products",
    'brandsTitle': "Brands we work with",
    'certificationsTitle': "Compliances and Certifications",
    'buttonContent' : 'Send an enquiry',
    'logo': Logo,
    'background': Background,
    'prodLinkArray': [
        {
            title: 'Rugs',
            link: '/#',
        },
        {
            title: 'Pillows',
            link: '',
        },
        {
            title: 'Poufs',
            link: '',
        },
        {
            title: 'Wall hangers',
            link: '/#',
        },
    ],

    'brandArray': [
        {
            title: 'Company 1',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 2',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 3',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 4',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 5',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 6',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 7',
            link: '/#',
            image: Background,
        },
        {
            title: 'Company 8',
            link: '/#',
            image: Background,
        },
    ],
    'prodArray': [
        {   title: 'Powerloom',
            image: Product_1,
            link: 'https://catalogue.sourcewiz.co/k1y5shf',
        },
        {
            title: 'Embroidery',
            image: Product_2,
            link: 'https://catalogue.sourcewiz.co/t7pfie1',
        },
        {
            title: 'Polyster prints',
            image: Product_3,
            link: 'https://catalogue.sourcewiz.co/xrv96im',
        },
        {
            title: 'Autoloom',
            image: Product_4,
            link: 'https://catalogue.sourcewiz.co/602naa1',
        },
        {
            title: 'Garments',
            image: Product_5,
            link: 'https://catalogue.sourcewiz.co/tezzjdr',
        },
    ],
    'imgArray': [

    ],

    'aboutData' : [

    ],

    'sustainabilityData' : {
        title : 'Sustainability is the way of life, the way of our manufacturing',
        text : '',
        image : sustainability,
    },

    'artisansData' : {
        title : "The artisans are the masters behind creating our world class products",
        text : "",
        image : artisans,
    },

    'backgroundImgArray' : [
        {image : background_1},
        {image : background_2},
        {image : background_3}
    ],


    emails : ['ap3919529@gmail.com'],
    // emails : ['rajatintl@gmail.com']
}

export default data;

