import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flexDirection  : 'column',
    width          : '100vw',
    zIndex         : -1,
    padding : '1.5em 0 0 0em',
    '@media only screen and (max-width : 1000px)' :{
        
    }
})

export const Box = styled('div', {
    display : 'flex',
    width : '80vw',
    margin : '2rem 0',
    '@media only screen and (max-width : 1000px)' :{
        flexDirection : 'column',
        alignItems : 'center',
    },
    '@media only screen and (max-width : 600px)' :{
        width : '90vw',
    }   
})
export const Content = styled('div', {
    width : '50%',
    padding : '1rem',
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'center',
    '@media only screen and (max-width : 1000px)' :{
        width : '100%',
        alignItems : 'flex-start',
        textAlign : 'left',
    },
})

export const Image = styled('img', {
    width : '95%',
    '@media only screen and (max-width : 600px)' :{
        width : '100%',
    }   
})

export const Heading = styled('h1', {
    
})

export const Para = styled('p', {
    lineHeight : '32px',
    margin : '2rem 0',
})














