import * as S from './Artisans.style.js'

const Artisans = ({data}) => {
    return (
        <S.Container>
            <S.Header>{data.title}</S.Header>
            <S.Image src={data.image} alt={data.title}/>
            <S.Text>
                Our skilled artisans are able to handle fabric production & preparation according to the buyer's design and color combination requirements. They have spinning and weaving capabilities to produce world-class fashion clothing that is loved globally. <br /> <br />
                No matter what design ideas you might be having on your mind, as per the look you want to flaunt, our designing specialists who are always upbeat with the latest trends in the fashion circuit can design, customize, and manufacture them.  <br />
            </S.Text>
        </S.Container>
    )
}

export default Artisans;